import React from "react";
import Typed from "react-typed";

const AboutOne = ({ title, info, img }) => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="https://i.ibb.co/qjBCMRY/300701885-395243982762097-4683972514078504698-n-removebg-preview.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  {title} <br />{" "}
                </h2>
                {info}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
