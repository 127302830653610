import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TeamTwo from "../elements/team/TeamTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import Carousel from "./Carousels";
import Carousels from "./Carousels";
import HeaderTopBar from "../common/header/HeaderTopBar";
var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Lvyuan Water Treatment Technology | LyWater Scam" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 bg-transparent height-650"
          style={{ paddingTop: "50px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span
                    className="subtitle"
                    style={{ color: "red", fontWeight: "strong" }}
                  >
                    Lywater Henan Lvyuan Water Treatment Technology
                    <br /> Scam Alert
                  </span>
                  <h1 className="title display-two" style={{ color: "red" }}>
                    Henan Lvyuan Water Treatment Technology Scam <br />{" "}
                    <Typed
                      strings={["Fraud", "Thieves", "Con Artists"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Save your company from being tarnished. <br />
                    Do not purchase from Henan Lvyuan Water Treatment
                    Technology. <br />
                    See Details Below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start About Area  */}
        <AboutOne
          info={
            <div>
              <p>
                Lywater or Henan Lvyuan Water Treatment Technology is a
                manufacturing company that is based out of China and retails
                their products and services on Alibaba.
                <br />
                What they do not tell you, is their products are backed by
                fraudulent inspections, and their quality is subpar.
                <br />
                Many customers that have purchased through Lywater via their
                website, and on Alibaba have come forward with complaints that
                the products that were sold were not what was advertised.
              </p>
            </div>
          }
          title="About Henan Lvyuan Water Treatment Technology"
          image="https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-6/300701885_395243982762097_4683972514078504698_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=N7aE4QLpwUUAX-i_zFX&_nc_ht=scontent-lga3-1.xx&oh=00_AfDclURocKnFClXosMvBIS9FnYKIjqB8dVX3Yze_JdQEAQ&oe=63C25EA7"
        />
        {/* End About Area  */}

        <Separator />
        <div
          className="rn-service-area "
          style={{ width: "70%", margin: "auto", padding: "auto" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Questions"
                  //description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <Carousels />
          </div>
        </div>
        <Separator />
        <div style={{ marginBottom: "2rem" }} />

        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
