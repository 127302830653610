import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
  {
    countNum: 199,
    countTitle: "Happy Clients.",
  },
  {
    countNum: 575,
    countTitle: "Employees",
  },
  {
    countNum: 69,
    countTitle: "Useful Programs",
  },
  {
    countNum: 500,
    countTitle: "Useful Programs",
  },
];

const SlipTwo = ({ title, description }) => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail">
              <img src="./images/split/split-02.jpg" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{description}</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipTwo;
