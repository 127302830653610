import React from "react";
import { Carousel } from "antd";
import SlipTwo from "../elements/split/SlipTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import { Collapse, Space } from "antd";
import { Card, Col, Row } from "antd";
import ContactOne from "../elements/contact/ContactOne";
import ContactForm from "../elements/contact/ContactForm";
import Contact from "../elements/contact/Contact";
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const answer = [
  {
    Q: "Will I receive the product that I ordered?",
    A: "They wil deliver the product that was ordered, however the quality of the product will be substandard to what you ordered.",
  },
  {
    Q: "Do they provide any inspections of the product prior to purchasing?",
    A: "Yes, they will provide inspections, however they will be fraudulent inspections as independent inspectors have noted.",
  },
  {
    Q: "How will the product affect my company?",
    A: "Customers that purchased the product will realize that the quality of the product is not up to standard, and will tarnish your company's reputation.",
  },
];

const report = [
  {
    Q: "1. to Chinese Embassies and Consulates",
    A: "You can make complaints to the Chinese Embassy or Consulates in your country. In some cases, the embassy or consulates will refer your complaints to the law enforcement agencies and trade promotion agencies in China, who have the authority to mediate your dispute with the Chinese supplier.",
  },
  {
    Q: "2. to Local Law Enforcement Agencies in China",
    A: "You can also make complaints to the Chinese government. Under normal conditions, it makes no sense to complain to the central government and its departments, since usually these departments are only responsible for policy formulation, not law enforcement. Instead, you should turn to government departments at local levels where the Chinese supplier resides, such as the local authorities for administration for market regulation or the local commission of commerce. With the aim of maintaining the local business environment, these local law enforcement agencies will be more than willing to help you settle the dispute. After all, export trade is a significant source of revenue for many local governments. But, there are times when some local law enforcement agencies may ignore your complaints, especially if the dispute involves very little money. This is because many local law enforcement agencies in China only act on orders from above, rather than on their own initiative. In any case, complaints are a relatively low-cost measure. Thus, you can give it a try, or ask your Chinese agents to do so.",
  },
  {
    Q: "3. to Business Associations or Chambers of Commerce",
    A: `If you find that the Chinese supplier has joined a business association or chamber of commerce, you can make complaints to these organizations.

    The organizations they join can be local or international.
    
    Some suppliers need to maintain their good reputation with these organizations, so they fear being tainted with scandal.
    
    Some suppliers have spent a great deal of money to join these organizations, so they fear being removed on account of an adverse record.`,
  },
  {
    Q: "Debt Collection",
    A: `You can also entrust a debt collection agent in China to communicate with the Chinese supplier on your behalf.

  These agents will adopt legitimate measures to urge the debtor to pay up.
  
  In many cases, Chinese suppliers dare to commit any fraud or default simply because you don’t have agents in China. The long distance and national borders make them believe that they bear no liability for fraud or default.
  
  Once you have agents in China, these Chinese suppliers will show more restraint.`,
  },
  {
    Q: "4. Litigation or Arbitration",
    A: `Litigation or arbitration against the supplier in China can be used as a last resort.`,
  },
];

export default function Carousels() {
  return (
    <Space direction="vertical">
      {answer.map((a) => (
        <Collapse accordion>
          <Panel header={a.Q} key="1">
            <p>{a.A}</p>
          </Panel>
        </Collapse>
      ))}
      <Space direction="horizontal" />
      <div style={{ textAlign: "center" }}>
        <h2>Complaints</h2>
        <p>Where to File Complaints</p>
      </div>
      {report.map((a) => (
        <Collapse accordion>
          <Panel header={a.Q} key="1">
            <p>{a.A}</p>
          </Panel>
        </Collapse>
      ))}

      <div className="site-card-wrapper">
        <div style={{ margin: "3rem" }} />
        <Card
          title={<h2>Please Report the Following:</h2>}
          bordered={false}
          style={{
            width: "80%",
            padding: "auto",
            margin: "auto",
            textAlign: "center",
          }}
        />
        <ContactOne />
      </div>

      <div style={{ marginBottom: "1rem" }} />
    </Space>
  );
}
